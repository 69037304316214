/* eslint-disable no-console,no-undef */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {NumberWrapper, ContentWrapper, ImageButton, StyledModal} from '../CompanyDashBoardV2/styles';
import {closeIcon} from '../../utils/icons';
import HoverButton from '../common/HoverButtonV2';
import {StyledInputV2} from '../CreateEvent/style';

class UpdateOrganization extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  onChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { show, onClose, domainName, onChangeInput, updateOrganizationData, type } = this.props;
    return (
      <StyledModal show={show} width={"600px"} height="auto" onHide={() => onClose()}>
        <NumberWrapper>
          <div className='crossIcon'>
            <HoverButton
              title="Close"
              width="24px"
              height="24px"
              svgPath={closeIcon()} 
              onClick={() => onClose()}
              backgroundColor={"#005C870D"}
              backgroundColorActive={"#005C87"}
            />
          </div>
          <div className='stepHeading'>
            {type=="create"?"Add Domain":"Update Domain"}
          </div>
        </NumberWrapper>
        <ContentWrapper border paddingValue="25px">
          <StyledInputV2
            placeholder={("Write organization domain here...")}
            name={type=="create"?"organizationDomainName":"domainName"}
            onChange={onChangeInput}
            value={domainName}
            maxLength="100"
            isPadding={" 0px 20px 0px 12px"}
          />
        </ContentWrapper>
        {<ContentWrapper border paddingValue="0 25px 25px 25px">
          <ImageButton 
            width="200px" 
            background={!domainName?"#005C874D":"#005C87"} 
            margin="auto"
            disable={!domainName}
            onClick={()=>updateOrganizationData()}
          >Submit</ImageButton>
        </ContentWrapper>}
      </StyledModal>
    );
  }
}

UpdateOrganization.propTypes = {
  show:PropTypes.bool,
  hidePopup: PropTypes.func,
  companyId: PropTypes.number,
  onClose: PropTypes.func,
  organizationDomain: PropTypes.string,
  domainName: PropTypes.string,
  onChangeInput: PropTypes.func,
  updateOrganizationData: PropTypes.func,
  type: PropTypes.string,
};

export default UpdateOrganization;