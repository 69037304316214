/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Layout} from './style';
import { toast } from "react-toastify";
import { fetchApi } from "../../utils/methods";
import { Connectioncallback } from "../../redux/constants/apiConstants";

class Success extends Component {
  constructor(props) {
    super();
    this.state = {
      companyId: props.companyInfo?.id,
    };
  }

  componentDidMount() {
    setTimeout(() => this.getSuccessCallback(), 4000);
  }

  getSuccessCallback = async () => {
    const companyId = this.state.companyId; 
    try {
      const res = await fetchApi(`${Connectioncallback}/${companyId}`, "GET");
      if (res.message) {
        toast.error(res.message);
      } else {
        this.props.history.push('/company/hris-integration')
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  render() {
    return (
      <Layout>
        <div className='main'>
          <h1>Connection is created successfully</h1>
        </div>
      </Layout>
    )
  }
}

Success.propTypes = {
  history: PropTypes.object.isRequired,
  companyInfo: PropTypes.object,
  companyBranding: PropTypes.object,
};

export default connect(null, null)(Success);
